$(function () {
    $('#offers-modal-toggle').click(function (e) {
        e.preventDefault();

        $('#offers-modal-container').css({
            display: 'flex'
        });
    });

    const radios = document.querySelectorAll('input[name="offer_who"]')
    const msgArea = document.querySelector('#msg');

    radios.forEach(function (element) {
        element.addEventListener('change', function (e) {
            const radioOther = document.querySelector('#other');
            if (radioOther.checked) {
                msgArea.style.display = 'block';
                msgArea.focus();
                msgArea.required = true;
            } else {
                msgArea.style.display = 'none';
                msgArea.value = '';
                msgArea.required = false;
            }
        });
    });

    $("#offers-modal-container").click(function (event) {
        if (event.target.id === "offers-modal") {
            return;
        }

        if ($(event.target).closest('#offers-modal').length) {
            return;
        }

        $("#offers-modal-container").hide();
    });

    $('#offer-submit').on('click', function (e) {

        e.preventDefault();
        if (isCaptchaChecked() && $('#offer-conditions input').prop('checked') == true) {
            $.ajaxSetup({
                header: $('meta[name="_token"]').attr('content')
            })
            $.ajax({
                type: "POST",
                url: '/object/ajaxOffer',
                data: $('#offer-form').serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        window.location.href = $('#offer-submit').data('href');
                    }
                }
            })
        } else if (isCaptchaChecked()) {
            $('#recaptcha-error').css('display', 'none');

            if ($('#offer-conditions input').prop('checked') == true) {
                $('#offer-conditions').css('color', 'black');
            } else {
                $('#offer-conditions').css('color', 'red');
            }
        } else {
            $('#recaptcha-error').css('display', 'block');
        }

    });


    var loaded = false;

    $('#streetview-toggle').click(function (e) {
        e.preventDefault();
        if (loaded === false) {
            initStreetview();
            loaded = true;
        }

        $('#streetview-container').css({
            display: 'flex'
        });
    });

    $("#streetview-container").click(function (event) {
        if (event.target.id === "streetview-modal") {
            return;
        }

        if ($(event.target).closest('#streetview-modal').length) {
            return;
        }

        $("#streetview-container").hide();
    });

    $('input[name="finance_terms"]').change(function () {
        switch ($(this).val()) {
            case 'reservation_amount':
                $('#reservation-amount').slideDown();
                break;
            default:
                $('#reservation-amount').slideUp();
        }
    });

    $('input[name="acceptance"]').change(function () {
        switch ($(this).val()) {
            case 'date':
                $('#acceptance-date').slideDown();
                break;
            default:
                $('#acceptance-date').slideUp();
        }
    });

    $('.go-to-next-step').click(function () {
        var currentStep = $(this).parent().data('step');
        var nextStep = parseInt(currentStep) + 1;
        var passed = true;

        $('input, textarea', '.step[data-step=' + currentStep + ']').each(function (k, v) {
            if (!v.checkValidity()) {
                v.reportValidity();
                passed = false;
            }
        });

        if (passed === true) {
            $(this).parent().fadeOut(function () {
                $('.step[data-step=' + nextStep + ']').fadeIn();
            });
        }
    });

    if ($('#mobile-object-photos').length > 0) {
        $('#mobile-object-photos').slick({
            dots: false,
            arrows: true,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<i class="icon icon-angle-left prev"></i>',
            nextArrow: '<i class="icon icon-angle-left next"></i>',
        });
    }

    initNav();
    initSlideshow();
    initSlideshowHome();
    initMatchHeight();
    initSearch();
    initSocial();
    initContact();
    initObjectCarousel();
    initAanbod();
    initDetails();

    initInternetExplorer();
});

function isCaptchaChecked() {
    return grecaptcha && grecaptcha.getResponse().length !== 0;
}

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        initNav();
    }, 200));
});

function initNav() {
    $btn_nav = $('header .nav-toggle');
    $nav = $('header nav');

    $btn_search = $('header .search-toggle');
    $search = $('#search_wrapper');

    $btn_nav.unbind().click(function (e) {
        e.stopPropagation();
        e.preventDefault();


        if ($(window).width() < 980) {
            $('body').addClass('nav-active');
            console.log($nav.is(':visible'));
            if (!$nav.is(':visible')) {
                $('body').addClass('nav-active');
                $search.slideUp(300);
                $nav.slideDown(300);
            } else {
                $('body').removeClass('nav-active');
                $search.slideUp(300);
                $nav.slideUp(300);
            }
        }
    });

    $btn_search.unbind().click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 980) {
            if (!$search.is(':visible')) {
                $nav.slideUp(300);
                $search.slideDown(300);
            } else {
                $('body').removeClass('nav-active');
                $nav.slideUp(300);
                $search.slideUp(300);
            }
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').unbind().click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle')
                    $(ul).slideUp();
                }
            });

            if ($(this).next('ul').is(':hidden')) {
                e.preventDefault();
                $(this).next('ul').slideDown();
                $(this).children('span').addClass('toggle')
            }
        });
    }

    $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
            $('body').addClass('nav-fixed');
            $('header .phone').removeClass('d-none');
            $('header .btn-color3').addClass('d-none');
        } else {
            $('body').removeClass('nav-fixed');
            $('header .phone').addClass('d-none');
            $('header .btn-color3').removeClass('d-none');
        }
    });
}

function initSlideshow() {
    if ($('#slideshow').length > 0) {
        $('#slideshow').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            focusOnSelect: false,
            slide: '.slide',
        });
    }
}

function initSlideshowHome() {
    if ($('#slideshow_home').length > 0) {

        $('#slideshow_home').slick({
            autoplay: false,
            autoplaySpeed: 10000,
            dots: true,
            arrows: false,
            infinite: false,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: false
        });

        $('#slideshow_home').on('afterChange', function (event, slick, currentSlide) {
            $('.slide-img').removeClass('active');
            $('.slide-content').hide();

            setTimeout(function () {
                $('#slideshow_home .slide_bg').eq(currentSlide).find('.slide-img').addClass('active');
                setTimeout(function () {
                    $('#slideshow_home .slide_bg').eq(currentSlide).find('.slide-content').fadeIn();
                }, 1500);
            }, 1000);
        });

        setTimeout(function () {
            $('#slideshow_home .slide_bg').eq(0).find('.slide-img').addClass('active');
            setTimeout(function () {
                $('#slideshow_home .slide_bg').eq(0).find('.slide-content').fadeIn();
            }, 1500);
        }, 1000);
    }
}

function initMatchHeight() {
    if ($('.matchheight').length > 0) {
        $('.matchheight').matchHeight();
    }
}

function initSearch() {
    $('select#plaats option').first().text('Kies een plaats');
    $('select#prijs_min option').first().text('Minimum prijs');
    $('select#prijs_max option').first().text('Maximum prijs');
    $('select#prijs_min_huur option').first().text('Minimum prijs');
    $('select#prijs_max_huur option').first().text('Maximum prijs');

    $select_koophuur = $('#koophuur');
    $select_koophuur.change(function () {
        if ($select_koophuur.val() == 'koop') {
            $('#search-huurprijs select').attr('disabled', 'disabled');
            $('#search-koopprijs select').removeAttr('disabled');
            $('#search-huurprijs').hide();
            $('#search-koopprijs').show();
        } else {
            $('#search-koopprijs select').attr('disabled', 'disabled');
            $('#search-huurprijs select').removeAttr('disabled');
            $('#search-koopprijs').hide();
            $('#search-huurprijs').show();
        }
    });
    $select_koophuur.change();
}

function initContact() {
    if ($('#contact').length > 0) {
        var latlng = new google.maps.LatLng($('#maps').data('lat'), $('#maps').data('lng'));

        map = new google.maps.Map(document.getElementById("maps"), {
            zoom: 18,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: '/img/icon-map.png'
        });
    }
}

function initSocial() {
    if ($('#socialCarousel').length > 0) {
        $('#socialCarousel').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
}

function initObjectCarousel() {
    if ($('#objectCarousel').length > 0) {
        $('#objectCarousel').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
}

function initAanbod() {
    if ($('.view-toggle').length > 0) {
        $('.view-map').hide();

        $('.view-toggle').click(function () {

            $('.view-toggle').removeClass('active');
            $(this).addClass('active');

            $('.view').hide();
            $('.' + $(this).data('toggle')).show();

            localStorage['view-toggle'] = $(this).data('toggle');

            if ($(this).data('toggle') == 'view-map') {

                $('.sort_wrapper').hide();

                var view = $(this).data('method');

                $.ajax({
                    dataType: "json",
                    url: window.location.origin + window.location.pathname + '.json' + window.location.search,
                    method: 'GET',
                    async: true
                }).done(function (data) {
                    initMap(data);
                });
            } else {
                $('.sort_wrapper').show();
            }
        });
    }

    if (localStorage['view-toggle'] == 'view-map') {
        $('.view-toggle[data-toggle=view-map]').click();
    }
}

function initMap(data) {
    var bounds = new google.maps.LatLngBounds();
    var infowindow = new google.maps.InfoWindow();

    map = new google.maps.Map(document.getElementById("maps"), {
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    markers = [];
    $(data).each(function (i, object) {

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(object.latitude, object.longitude),
            map: map,
            icon: '/img/icon-map.png'
        });

        bounds.extend(marker.position);

        google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
                infoBubble = new InfoBubble({
                    map: map,
                    content: '<a href="' + object.url + '" class="object">\n\
                                <figure>\n\
                                    <img src="' + object.hoofdfoto + '" alt="' + object.adres + ' ' + object.plaats + '">\n\
                                    <figcaption>' + object.prijs + '</figcaption>' + object.label + '\n\
                                </figure>\n\
                                <div class="text">\n\
                                    <h2>' + object.adres + '</h2>\n\
                                    <h3>' + object.plaats + '</h3>\n\
                                </div>\n\
                            </a>',
                    shadowStyle: 0,
                    padding: 0,
                    borderRadius: 0,
                    arrowSize: 0,
                    borderWidth: 0,
                    minWidth: 320,
                    maxWidth: 320,
                    closeSrc: '/img/infobubble-close.png'
                });

                infoBubble.open(map, marker);
            }
        })(marker, i));

        markers.push(marker);
    });

    map.fitBounds(bounds);

    var mc = new MarkerClusterer(map, markers, {
        imagePath: '/img/m'
    });
}

function initDetails() {
    if ($('#details').length > 0) {
        initDetailSlider();
        initDetailButtons();
        initDetailOmschrijving();
        initBrochureModal();
        initMaps();
        initObjectPano();

        if ($('.lightbox').length > 0) {
            $('.lightbox').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        }
    }
}

function initDetailButtons() {
    if ($('.detail_buttons').length > 0) {
        var sections = {},
            offset = 90;

        $('.detail_buttons a').click(function () {
            $('.detail_buttons li').removeClass('active');
            $(this).parent().addClass('active');

            // adjust for fixed header
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                && location.hostname == this.hostname) {

                var target = $(this.hash);

                target = target.length ? target : $('[id=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target[0].offsetTop - offset
                    }, 1000);
                    return false;
                }
            }
        });

        $('.detail_buttons a').each(function () {
            id = $(this).attr('href');

            if (id !== '#') {
                sections[id] = $(id).offsetTop;
            }
        });

        $(document).scroll(function () {
            var pos = $(this).scrollTop();

            for (key in sections) {
                if (sections[key] > pos && sections[key] < pos + offset) {
                    $('.detail_buttons li').removeClass('active');
                    $('.detail_buttons a[href="' + key + '"]').parent().addClass('active');
                }
            }
        });
    }
}

function initDetailSlider() {
    if ($('#details-slider').length > 0) {
        $slider = $('#details-slider');
        $thumbs = $('#details-thumbs');

        $slider.slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '#details-thumbs'
        });

        $thumbs.slick({
            dots: false,
            arrows: true,
            prevArrow: '<i class="icon icon-angle-left prev"></i>',
            nextArrow: '<i class="icon icon-angle-left next"></i>',
            infinite: true,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
            asNavFor: '#details-slider'
        });
    }
}

function initBrochureModal() {
    // $('.brochure-modal').modal();
}

function initStreetview() {
    if ($('#gmap_streetview').length > 0) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({'address': $('#gmap_streetview').data('address')}, function (results, status) {
            var lookTo = results[0].geometry.location;
            if (status == google.maps.GeocoderStatus.OK) {
                var panoOptions = {
                    position: lookTo,
                    panControl: false,
                    addressControl: false,
                    linksControl: false,
                    zoomControlOptions: false
                };
                var pano = new google.maps.StreetViewPanorama(document.getElementById('gmap_streetview'), panoOptions);
                var service = new google.maps.StreetViewService;
                service.getPanoramaByLocation(pano.getPosition(), 50, function (panoData) {
                    if (panoData != null) {
                        var panoCenter = panoData.location.latLng;
                        var heading = google.maps.geometry.spherical.computeHeading(panoCenter, lookTo);
                        var pov = pano.getPov();
                        pov.heading = heading;
                        pano.setPov(pov);
                    } else {
                        $('#gmap_streetview').html('Kan streetview niet genereren');
                    }
                });
            } else {
                $('#gmap_streetview').html('Kan streetview niet genereren');
            }
        });
    }
}

function initMaps() {
    if ($('#gmap_locatie').length > 0) {
        var latlng = new google.maps.LatLng($('#gmap_locatie').data('lat'), $('#gmap_locatie').data('lng'));

        map = new google.maps.Map(document.getElementById("gmap_locatie"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: '/img/icon-map.png'
        });
    }
}

function initDetailOmschrijving() {
    if ($('a.meer').length > 0) {
        $('a.meer').click(function (e) {
            e.preventDefault();
            $('.description_short').slideUp(function () {
                $('.description_long').slideDown();
            });
        });
    }
}

function initObjectPano() {
    $('[id^=pano-]').each(function (index) {
        id = $(this).attr('data-id');
        data = {
            js: '/js/pano/pano.js',
            swf: '/js/pano/pano.swf',
            // xml: window.location.origin + window.location.pathname + '/pano/' + id + '.xml',
            target: 'pano-' + id,
            html5: 'prefer',
            passQueryParameters: true
        };
        embedpano(data);
    });
}

function initInternetExplorer() {
    var version = detectIE();

    if (version === false) {
        // Do nothing
    } else {
        $('.btn').each(function () {
            var height = $(this).outerHeight();
            $(this).css({
                height: height,
                lineHeight: height + 'px'
            });
        });

        $('figure').each(function () {
            var height1 = $(this).find('i.label').outerHeight();
            var height2 = $(this).find('figcaption span.bg_color1').outerHeight();
            $(this).find('i.label').css({
                height: height1,
                lineHeight: height2 + 'px'
            });
            $(this).find('figcaption span.bg_color1').css({
                height: height2,
                lineHeight: height2 + 'px'
            });
        });

        $('select').each(function () {
            var height = $(this).outerHeight();
            $(this).css({
                background: '#fff',
                height: height,
                lineHeight: height + 'px'
            })
        });

        $('input[type="text"]').each(function () {
            var height = $(this).outerHeight();
            $(this).css({
                height: height,
                lineHeight: '27px'
            });
        });
    }
}

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}