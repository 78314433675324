$(function() {
    initHelpmeeCarousel();
    initBeschrijvingLeesmeer();
    initKenmerkenPopover();
    initVoorzieningen();
});

function initHelpmeeCarousel() {
    if ($('.helpmee_carousel_beschrijving').length) {
        $('.helpmee_carousel_beschrijving').slick({
            mobileFirst: true,

            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true, 
            arrows: true,
            prevArrow: '<span class="arrow prev">&lsaquo;</span>',
            nextArrow: '<span class="arrow next">&rsaquo;</span>',
            // adaptiveHeight: true,

            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        });
    }

    if ($('.helpmee_carousel_media').length) {
        initHelpmeeMedia();

        $('.helpmee_carousel_media').slick({
            mobileFirst: true,

            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: true,
            prevArrow: '<span class="arrow prev">&lsaquo;</span>',
            nextArrow: '<span class="arrow next">&rsaquo;</span>',

            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                }
            ]
        });
    }
}

function initBeschrijvingLeesmeer() {
    $('.helpmee_carousel_beschrijving .beschrijving').each(function() {
        if ($(this).find('.txt p').outerHeight() > 115) {
            $(this).find('.leesmeer').css({
                display: 'block'
            });
        }
    });
    $('.helpmee_carousel_beschrijving .beschrijving .txt').next('.leesmeer').click(function() {
        $(this).prev('.txt').toggleClass('active');

        if ($(this).prev('.txt').hasClass('active')) {
            $(this).prev('.txt').css({
                maxHeight: $(this).prev('.txt').find('p').outerHeight() + 60
            });
            $(this).text('Lees minder');
        } else {
            $(this).prev('.txt').css({
                maxHeight: 115
            });
            $(this).text('Lees meer');
        }
    });
}

function initKenmerkenPopover() {
    if ($('#helpmee_kenmerken').length) {
        $('.kenmerk').each(function() {
            $(this).find('.amount').click(function() {
                if ($(this).find('.endorsers').hasClass('active')) {
                    $(this).find('.endorsers').removeClass('active');
                } else {
                    $('.endorsers.active').removeClass('active');
                    $(this).find('.endorsers').addClass('active');
                }
            });
        });
    }
}

function initVoorzieningen() {
    if ($('#helpmee_voorzieningen').length) {
        var locations = [],
            markers = [],
            voorzieningen = JSON.parse($('#helpmee_map').data('json').replace(/\\/g, '')),
            amount = 0;

        voorzieningen.forEach(function(e) {
            amount++;
        });

        for (var i = 0; i < amount; i++) {
            locations.push(
                {
                    name: voorzieningen[i].name,
                    lat: parseFloat(voorzieningen[i].position.lat),
                    lng: parseFloat(voorzieningen[i].position.lng),
                    type: voorzieningen[i].category
                }
            );
        }

        var map = new google.maps.Map(document.getElementById('helpmee_map'), {
            center: { lat: parseFloat($('#helpmee_map').data('lat')), lng: parseFloat($('#helpmee_map').data('lng')) },
            zoom: 15
        });

        var icon = new google.maps.MarkerImage(
            '/img/icon-map.png',
            null, /* size is determined at runtime */
            null, /* origin is 0,0 */
            null /* anchor is bottom center of the scaled image */
        );
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(parseFloat($('#helpmee_map').data('lat')), parseFloat($('#helpmee_map').data('lng'))),
            map: map,
            icon: icon
        });

        for (i = 0; i < amount; i++) {
            var icon = new google.maps.MarkerImage(
                '/img/icon-map.png',
                null, /* size is determined at runtime */
                null, /* origin is 0,0 */
                null, /* anchor is bottom center of the scaled image */
                new google.maps.Size(25, 36)
            );

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i].lat, locations[i].lng),
                map: map,
            });

            google.maps.event.addListener(marker, 'click', (function (marker, i) {
                return function () {
                    infoBubble = new InfoBubble({
                        map: map,
                        content:
                            '<div class="inner">' +
                                '<h3>' + locations[i].name + '</h3>' +
                            '</div>',
                        shadowStyle: 0,
                        padding: 0,
                        borderRadius: 0,
                        arrowSize: 10,
                        borderWidth: 0,
                        // minWidth: ,
                        maxWidth: 200,
                        closeSrc: '../img/helpmee/close.svg'
                    });

                    infoBubble.open(map, marker);
                }
            })(marker, i));

            markers.push(marker);
        }
    }
}

function initHelpmeeMedia() {
    $('.helpmee_carousel_media .slide').each(function() {
        $(this).click(function(e) {
            e.preventDefault();

            $('.helpmee_media_overlay img').attr('src', $(this).attr('href'));

            $('.helpmee_media_overlay').css({
                opacity: 1,
                visibility: 'visible'
            });
            $('.helpmee_media_overlay span').css({
                top: 'calc(50% + ' + (($('.helpmee_media_overlay img').outerHeight() / 2) + 20) + 'px)'
            });
            $('body').css({
                overflow: 'hidden'
            });
        });
    });

    $('.helpmee_media_overlay').click(function() {
        $(this).css({
            opacity: 0,
            visibility: 'hidden'
        });
        $('body').css({
            overflow: 'inherit'
        });
    });
}